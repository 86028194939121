import React, { useEffect } from 'react';
import { Typography } from '@one-thd/sui-atomic-components';
import { paintTypes } from '../../../data/paint-modals';
/* eslint-disable tailwindcss/no-arbitrary-value */
export const PaintType = () => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('paintType.ready'); }, []);

  const { description, types } = paintTypes;

  return (
    <div data-component="PaintModalContentPaintType">
      <div className="sui-mb-4">
        <Typography variant="body-base">{description}</Typography>
      </div>
      <div className="sui-flex sui-flex-col lg:sui-flex-row sui-justify-between">
        {types?.map((type) => {
          const { imageUrl, options, paintType } = type;
          return (
            <div
              className="sui-flex sui-bg-subtle sui-pt-2 lg:sui-pt-10 lg:sui-w-[49%] sui-mb-4 sui-pb-4"
              key={paintType}
            >
              <img
                className="sui-py-10 sui-px-5 sui-w-6/12"
                src={imageUrl}
                alt={paintType}
                height="auto"
                width="auto"
              />
              <div className="sui-w-6/12 sui-pr-5 sui-flex-[0_1_100%] lg:sui-flex-none">
                <Typography variant="h5">{paintType}</Typography>
                <ul className="sui-list-disc">
                  {options.map((option, index) => {
                    return (
                      <li className="sui-ml-5 sui-mt-4" key={`option-${index + 1}`}>{option}</li>
                    );
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

PaintType.displayName = 'PaintType';
