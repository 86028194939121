import { useContext } from 'react';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { useLocation } from '@thd-olt-component-react/router';
import objectPath from 'object-path';
import { QueryContext } from '@thd-nucleus/data-sources';

import type { Props } from '../types';
import type { Component } from '../util';

type IPropContext = {
  store: any;
  expContext: any;
  pathname: string;
  itemId: string;
}
type IOpts = {
  mergeArray?: boolean,
  itemId?: string,
}
export const useHydratedProps = (component: Component | Component[], opts: IOpts = {}) => {
  const expContext = useContext(ExperienceContext);
  const store = useStore();
  const { pathname } = useLocation();

  const ctx = {
    store,
    expContext,
    pathname,
    itemId: opts.itemId
  } as IPropContext;

  if (Array.isArray(component)) {
    const ret = component.map((comp) => hydrateProps(comp.props, ctx));
    if (opts.mergeArray) {
      return ret.reduce((acc, cur) => {
        return {
          ...acc,
          ...cur,
        };
      });
    }
    return ret;
  }
  return hydrateProps(component.props, ctx);
};

export const hydrateProps = (props: Props, ctx:IPropContext): any => {
  const { channel } = ctx.expContext;
  const { store } = ctx;
  let path = ctx.pathname;
  if (!path && typeof window !== 'undefined') {
    path = window.location.pathname;
  }
  const updatedProps = Object.keys(props).reduce((acc, cur) => {
    const val = props[cur];

    if (val === '$channel') {
      return {
        ...acc,
        [cur]: channel,
      };
    }

    if (val === '$itemId') {
      let itemId = '';

      if (ctx.itemId) {
        itemId = ctx.itemId
      } else if (/(?:\/[\w-]+)?\/p(?:\/[\w-]+)?\/\d+/.test(path)) {
        [, itemId] = /(?:\/[\w-]+)?\/p(?:\/[\w-]+)?\/(\d+)/.exec(path);
      }
      return {
        ...acc,
        [cur]: itemId,
      };
    }

    if (val === '$storeId') {
      return {
        ...acc,
        [cur]: store.storeId
      };
    }

    if (val === '$storeZip') {
      return {
        ...acc,
        [cur]: store.storeZip
      };
    }

    if (val === '$pathname') {
      return {
        ...acc,
        [cur]: path
      };
    }

    // support nested objects
    if (typeof val === 'object') {
      return {
        ...acc,
        [cur]: hydrateProps(val, ctx),
      };
    }
    return {
      ...acc,
      [cur]: val,
    };
  }, {});
  return updatedProps;
};
