import React, { useState } from 'react';
import {
  validatePipFlip
} from '../../utils/product-utils';
import { HammerWrenchIcon, LeadingIcon } from './PipFlipIcon';

const PipFlipButton = (props) => {
  const { productData, displayConfigurator, hasPipFlip, triggerPipFlip } = props || {};
  const caretImg = 'https://assets.thdstatic.com/images/v1/caret-orange.svg';
  const caretHoverImg = 'https://assets.thdstatic.com/images/v1/caret-white.svg';
  const [caretImgSrc, setCaretImgSrc] = useState(caretImg);

  const hasPipFlipCookie =
    typeof document !== 'undefined' && cookieUtils
      ? cookieUtils.fed_ReadCookie('tnt_pipflip')
      : null;

  const customExperience = productData?.product?.info?.globalCustomConfigurator?.customExperience;
  const customTitle = productData?.product?.info?.globalCustomConfigurator?.customTitle;


  if (!productData || displayConfigurator) {
    return null;
  }

  const pipFlipValidation = validatePipFlip(productData, hasPipFlip);

  return (
    <>
      {hasPipFlipCookie && pipFlipValidation && (
        <div class="sui-p-3 sui-pt-0 sui--mt-5 sui-bg-primary">
          <div className="attribute__label">
            <div className="label">Customize:</div>
          </div>
          <button
            type="button"
            className="super-sku__inline-tile border-radius--medium"
            onClick={triggerPipFlip}
            style={{ marginTop: 5, alignItems: 'center' }}
          >
            {HammerWrenchIcon}
            {hasPipFlipCookie === 'A' && pipFlipValidation && (
              <span style={{ paddingLeft: 10 }}>Create Your Own</span>
            )}
            {hasPipFlipCookie === 'B' && pipFlipValidation && (
              <span style={{ paddingLeft: 10 }}>See Additional Options</span>
            )}
          </button>
        </div>
      )}
      {pipFlipValidation &&
        customExperience === 'stock-blinds-pipflip' &&
        !customTitle && (
          <div class="sui-p-3 sui-pt-0 sui--mt-5 sui-bg-primary">
            <div className="attribute__label">
              <div className="label">Customize:</div>
            </div>
            <button
              type="button"
              className="sui-btn sui-btn-secondary sui-btn-secondary-focus
          sui-btn-secondary-active sui-btn-secondary-hover
          sui-font-bold sui-text-base sui-leading-normal sui-tracking-normal
          sui-normal-case sui-line-clamp-unset"
              onClick={triggerPipFlip}
              style={{ marginTop: 5, alignItems: 'center' }}
              onMouseEnter={() => setCaretImgSrc(caretHoverImg)}
              onMouseLeave={() => setCaretImgSrc(caretImg)}
            >
              {LeadingIcon}
              <span style={{ paddingLeft: 10, paddingRight: 10 }}>
                See More Custom Sizes
              </span>
              <img
                src={caretImgSrc}
                alt="caret"
                height="16"
                width="16"
                loading="lazy"
                style={{ height: '16px', width: '16px' }}
              />
            </button>
          </div>
        )}
    </>
  );
};

export { PipFlipButton };