import React from 'react';
import { calculateSize } from '../../../../data/paint-modals';

export const Footer = () => {

  return (
    <div className="sui-mt-3 sui-text-xs sui-text-subtle">
      PLEASE NOTE: {calculateSize.disclaimer}
    </div>
  );
};

Footer.displayName = 'Footer';
