import React from 'react';
import { Skeleton } from '@one-thd/sui-atomic-components';

export const AccordionTitlePlaceholder = () => (
  <div data-component="QuestionsAndAnswersAccordionTitlePlaceholder">
    <Skeleton
      type="rect"
      height="50px"
      width="100%"
    />
  </div>
);

AccordionTitlePlaceholder.displayName = 'QuestionsAndAnswersAccordionTitlePlaceholder';