import React from 'react';
import { oneOf } from 'prop-types';
import {
  Table, TableBody, TableCell, TableRow, Typography
} from '@one-thd/sui-atomic-components';
import { brands, brandNames } from '../../../data/paint-modals';
import { getBrandKey, validateBrandData } from './brand-utils';

const Brand = ({ brandName, type }) => {
  const brandKey = getBrandKey(brandName);

  if (brandKey === null) {
    return (
      <div className="sui-w-full sui-h-72 sui-flex sui-flex-col sui-justify-center sui-text-center">
        <h2>Sorry, there is no content available for this brand.</h2>
      </div>
    );
  }

  const brandData = brands[brandKey]?.[type];
  const disclaimers = brands[brandKey]?.disclaimers;

  const { error, success } = validateBrandData(brandData);

  if (!success) {
    return (
      <div data-testId="brand-modal-error">
        <h1>CONTENT ERROR:</h1>
        <h4>{error}</h4>
      </div>
    );
  }

  return (
    <div
      data-component="PaintModalContentBrand"
      data-testId="modal-content"
    >
      <div className="sui-mb-4">
        <Typography variant="body-base">Our top paint brands side by side. Compare performance,
          durability, value, and more.
        </Typography>
      </div>
      <div className="sui-max-h-full sui-max-w-full sui-overflow-auto">
        <div className="sui-max-h-full sui-max-w-full sui-overflow-auto">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" sticky="horizontal" disableShadow />
                {brandData.map((brand) => {
                  const { image = null, name, showName = false } = brand;

                  return (
                    <TableCell>
                      <div className="sui-flex sui-flex-col sui-justify-center sui-align-middle sui-w-full sui-h-24">
                        {image && (
                          <img
                            className="sui-mx-auto"
                            src={image}
                            alt={name}
                            key={name}
                            width={96}
                            height={96}
                          />
                        )}
                        {showName && (
                          <div className="sui-mx-auto sui-mt-auto">
                            <Typography>
                              {name}
                            </Typography>
                          </div>
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
              {Object.keys(brandData[0])
                .filter((key) => key !== 'name' && key !== 'image' && key !== 'showName')
                .map((key) => {
                  return (
                    <TableRow>
                      <TableCell component="th" sticky="horizontal" disableShadow>{key}</TableCell>
                      {brandData.map((brand) => {
                        return (<TableCell key={`${brand.name}-${key}`} align="center">{brand[key]}</TableCell>);
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <div className="sui-mt-4 sui-flex sui-flex-col sui-gap-1 sui-text-xs">
          {disclaimers.map((disclaimer, index) => {
            return <div>{`${Array(index + 1).fill('*').join('')} ${disclaimer}`}</div>;
          })}
        </div>
      </div>
    </div>
  );
};

Brand.displayName = 'PaintModalContent.Brand';

Brand.propTypes = {
  brandName: oneOf(Object.entries(brandNames).reduce((curr, accum) => accum.concat(curr[1], []))).isRequired,
  type: oneOf((['Interior Paint', 'Exterior Paint']))
};

Brand.defaultProps = {
  type: 'Interior Paint',
};

export { Brand };
