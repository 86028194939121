/* eslint-disable react/forbid-prop-types */
/* eslint-disable tailwindcss/no-arbitrary-value */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  params, string, shape, bool, useDataModel
} from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { useImpression } from '@thd-olt-component-react/impression';
import { Typography, RatingMeter } from '@one-thd/sui-atomic-components';
import { getCustomUrlWithAnalytics } from '../../productPodUtils';

export const ProductRating = (
  {
    itemId, target, onClick, openDetailsInNewTab, isIframe, iframeAnalyticsData, merchQueryParam
  }) => {
  const { hosts } = useContext(ExperienceContext);
  const { additionalData = {} } = useImpression({
    data: {
      id: itemId,
      component: 'ProductRating',
      name: 'ProductRating',
      position: 0,
    },
  });

  const { parent } = additionalData || {};

  const [sponsoredValues, setSponsoredValues] = useState({});
  const [reviewsData, setReviewsData] = useState({});

  const { data } = useDataModel('product', {
    variables: {
      itemId,
    },
  });

  const { product } = data || {};
  const { info, identifiers, reviews } = product || {};

  useEffect(() => {
    if (reviews) {
      setReviewsData({
        totalReviews: reviews?.ratingsReviews?.totalReviews,
        averageRating: reviews?.ratingsReviews?.averageRating,
        canonicalUrl: identifiers?.canonicalUrl,
        productType: identifiers?.productType,
      });
    }
  }, [reviews, identifiers?.canonicalUrl, identifiers?.productType]);

  useEffect(() => {
    if (info) {
      setSponsoredValues({
        isSponsored: sponsoredValues?.isSponsored || info?.isSponsored,
        sponsoredBeacon: sponsoredValues?.sponsoredBeacon || info?.sponsoredBeacon,
        sponsoredMetadata: sponsoredValues?.sponsoredMetadata || info?.sponsoredMetadata,
      });
    }
  }, [info?.isSponsored, info?.sponsoredBeacon, info?.sponsoredMetadata]);

  let totalReviews = reviews?.ratingsReviews?.totalReviews || reviewsData?.totalReviews;
  let averageRating = reviews?.ratingsReviews?.averageRating || reviewsData?.averageRating;

  if (!totalReviews && !averageRating && !reviews) return null;

  const canonicalUrl = identifiers?.canonicalUrl || reviewsData?.canonicalUrl;
  const productType = identifiers?.productType || reviewsData?.productType;
  const averageRatingHasValue = Number(averageRating) > 0;

  const hasRatings = Boolean(
    typeof averageRating === 'string'
    && (typeof totalReviews === 'string' || typeof totalReviews === 'object')
  );

  if (!hasRatings) return null;

  totalReviews = parseInt(totalReviews, 10);
  averageRating = Number(averageRating).toFixed(1);

  const productURL = getCustomUrlWithAnalytics({
    productType,
    blindsHost: hosts?.customBlinds,
    canonicalUrl,
    info,
    sponsoredValues,
    merchQueryParam
  });

  const handleClick = (event, action = null) => {
    const productRatingEventData = {
      podAction: 'product rating',
      podAnchorSku: itemId,
      target,
      parent,
    };

    if (isIframe) {
      window.top.LIFE_CYCLE_EVENT_BUS.trigger('cartItem.DD_EVENT', iframeAnalyticsData);
    } else {
      window.LIFE_CYCLE_EVENT_BUS.trigger('product-pod-v7.click', productRatingEventData);
    }
    if (onClick) onClick(event, product, action);
  };

  const customReviews = (
    <>
      {averageRatingHasValue
        && (
          <Typography color="primary" component="span" height="tight" variant="body-xs">
            ({Number(averageRating)} /&nbsp;
            <Typography color="subtle" component="span" height="tight" variant="body-xs">
              {totalReviews}
            </Typography>
            )
          </Typography>
        )}
    </>
  );

  return (
    <div className="[&_a]:sui-p-0 [&_svg]:sui-w-3">
      <RatingMeter
        edge="end"
        size="small"
        target={target}
        onClick={handleClick}
        value={averageRating}
        label={customReviews}
        data-testid="product-pod__ratings-link"
        href={openDetailsInNewTab ? `${productURL}#product-section-rr` : ''}
        RatingProps={{
          color: 'brand',
        }}
      />
    </div>
  );
};

ProductRating.displayName = 'ProductRating';

ProductRating.dataModel = {
  product: params({ itemId: string().isRequired() }).shape({
    dataSource: string(),
    itemId: string(),
    reviews: shape({
      ratingsReviews: shape({
        averageRating: string(),
        totalReviews: string(),
      }),
    }),
    info: shape({
      isSponsored: bool(),
      sponsoredBeacon: shape({
        onClickBeacon: string(),
        onViewBeacon: string(),
      }),
      sponsoredMetadata: shape({
        campaignId: string(),
        placementId: string(),
        slotId: string(),
      }),
      globalCustomConfigurator: shape({
        customExperience: string(),
      }),
    }),
    identifiers: shape({
      canonicalUrl: string(),
      productType: string(),
    }),
  }),
};

ProductRating.propTypes = {
  itemId: PropTypes.string.isRequired,
  target: PropTypes.string,
  onClick: PropTypes.func,
  openDetailsInNewTab: PropTypes.bool,
  iframeAnalyticsData: PropTypes.object,
  isIframe: PropTypes.bool,
  merchQueryParam: PropTypes.string
};

ProductRating.defaultProps = {
  target: null,
  onClick: null,
  openDetailsInNewTab: true,
  isIframe: false,
  iframeAnalyticsData: null,
  merchQueryParam: null
};
