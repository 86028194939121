import React, { useContext, useEffect } from 'react';
import classNames from 'classnames/bind';
import { string } from 'prop-types';
import {
  Table, TableBody, TableCell, TableRow,
  Typography
} from '@one-thd/sui-atomic-components';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { sheens } from '../../../data/paint-modals';
import styles from './Sheen.module.scss';
/* eslint-disable tailwindcss/no-arbitrary-value */

const cx = classNames.bind(styles);

const FINISH_PERFORMANCE_LABEL = 'Finish Performance';
const BEST_USES_LABEL = 'Best Uses';
const BEST_SUITED_LOCATIONS_LABEL = 'Best Suited Locations';

export const Sheen = ({ colorHex }) => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('brands.ready'); }, []);

  return (
    <div
      className={cx('sheen-modal', 'sui-h-full')}
      data-component="PaintModalContentSheen"
    >
      <div className="sui-mb-4">
        <Typography variant="body-base">
          Take the guesswork out of finding the perfect paint sheen for your next paint project.
        </Typography>
      </div>
      <div className="sui-h-full sui-max-w-full sui-overflow-auto">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" sticky="horizontal" disableShadow />
              {sheens.map((sheen) => {
                const { image, name } = sheen;
                return (
                  <TableCell align="center">
                    <div>
                      <img
                        className="sui-p-0 sui-w-36 sui-h-20"
                        src={image}
                        alt={name}
                        style={{ backgroundColor: colorHex }}
                        key={name}
                        width={147}
                        height={84}
                      />
                      <div className="sui-flex sui-mt-1 sui-text-base sui-font-bold sui-text-nowrap">{name}</div>
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell component="th" sticky="horizontal" disableShadow>{FINISH_PERFORMANCE_LABEL}</TableCell>
              {sheens.map((sheen) => {
                const { name, finishPerformance } = sheen;
                return (
                  <TableCell key={name}>{finishPerformance}</TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell component="th" sticky="horizontal" disableShadow>{BEST_USES_LABEL}</TableCell>
              {sheens.map((sheen) => {
                const { name, bestUses } = sheen;
                return (
                  <TableCell key={name}>{bestUses}</TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell component="th" sticky="horizontal" disableShadow>{BEST_SUITED_LOCATIONS_LABEL}</TableCell>
              {sheens.map((sheen) => {
                return (
                  <TableCell key={sheen.name}>
                    {
                      sheen.bestSuitedLocations.map((location) => {
                        return (
                          <Typography
                            variant="body-base"
                            height="tight"
                            weight="regular"
                            key={location}
                          >{location}
                          </Typography>
                        );
                      })
                    }
                  </TableCell>
                );
              })}
            </TableRow>
            {!isMobile && (
              <TableRow>
                <TableCell component="th" sticky="horizontal" disableShadow />
                <TableCell colSpan="5">
                  <div className={cx('arrow')}> </div>
                  <div className="sui-flex sui-justify-between">
                    <div className="sui-font-bold sui-ml-[9%]">LESS STAIN RESISTANCE</div>
                    <div className="sui-font-bold sui-mr-[9%]">SUPERIOR STAIN RESISTANCE</div>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

Sheen.propTypes = {
  colorHex: string.isRequired,
};

Sheen.displayName = 'PaintModalContent.Sheen';
