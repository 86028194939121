import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Tabs,
  Tab,
  Typography
} from '@one-thd/sui-atomic-components';
import { WallAreaCalculator } from '@thd-olt-component-react/calculator';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { EasyEstimator } from './EasyEstimator/EasyEstimator';
import { calculateSize } from '../../../data/paint-modals';

const tabLabels = ['Paint Calculator', 'Easy Estimator'];

export const ContainerSize = () => {
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('containerSize.ready');
  }, []);
  const [index, setIndex] = useState(0);

  const experienceContext = useContext(ExperienceContext);

  const handleTabChange = () => {
    setIndex(1);
  };

  const handleToggle = (event, newValue) => {
    setIndex(newValue);
  };

  const introPane = (
    <div className="sui-flex sui-flex-col sui-items-center">
      <img
        className="sui-mb-4 sui-w-1/5"
        src="https://assets.thdstatic.com/images/v1/paint/1-gallon-bucket.svg"
        alt="One Gallon Paint Bucket"
        height={experienceContext?.channel === 'mobile' ? '60px' : '109px'}
        width={experienceContext?.channel === 'mobile' ? '59px' : '108px'}
      />
      <div>
        Enter your room&apos;s measurements to get your results or check out the&nbsp;
        <Button
          variant="text"
          onClick={handleTabChange}
          className="sui-underline sui-mb-1"
        >
          Easy Estimator
        </Button>&nbsp;for a more
        general estimate.
      </div>
    </div>
  );

  const errorMessage = 'One or more fields have invalid input(s). Unable to calculate.';

  return (
    <div data-component="PaintModalContentContainerSize">
      <Tabs value={index} onChange={handleToggle} variant="fullWidth">
        {tabLabels.map((label, labelIndex) => {
          return (
            <Tab
              key={labelIndex}
              label={label}
            />
          );
        })}
      </Tabs>
      {index === 0 && (
        <div className="sui-grid sui-grid-cols-1 sui-m-2">
          <WallAreaCalculator
            errorMessage={errorMessage}
            introPane={introPane}
            disclaimer={calculateSize.disclaimer}
          />
        </div>
      )}
      {index === 1 && (
        <div className="sui-grid sui-grid-cols-1 sui-m-2">
          <EasyEstimator />
        </div>
      )}
    </div>
  );
};

ContainerSize.displayName = 'ContainerSize';