import React, {
  ComponentType,
  useState,
  ReactElement,
  useEffect,
  useContext,
} from 'react';
import cs from 'classnames';
import { extend } from '@thd-nucleus/data-sources';
import { DynamicComponent, AppContext } from '@thd-nucleus/app-render';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { Hydrator } from '@thd-olt-component-react/hydrator';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Skeleton,
  SkeletonContent,
  SkeletonLine,
} from '@one-thd/sui-atomic-components';

import { withBypassComponent } from './withBypassComponent';
import type { IAppContext } from '../types';

interface InjectedProps {
  fusionClassName?: string;
  fusionSlotName?: string;
  fusionComponentName?: string;
  itemId: string;
  businessRules: string;
  config: any;
  // choices needs to match api, its not a 2d tuple
  choices: number[][];
  sduiData: SDUIAccordionComponent[][];
  queryProvider: any;
  slot: string;
  isDynamic: boolean;
}

type SDUIAccordionComponent = {
  index: number;
  data: {
    name: string;
    export: string;
    props: any;
  };
};

type BusinessRulesType = {
  itemId: string;
  componentDisplayName: string;
  children: (props: any) => ReactElement<any, any>;
  props: any;
};

type Expanded = {
  [key: number]: boolean;
};

/**
 *
 * @param components - a 3D array
 *
 *  [ //<-- this is a tuple of length 2, header and bodies
 *    [ //<-- these are the headers array of slots
 *      [ // <-- this is a components array of choices for position at index
 *         { the component data }
 *      ]
 *    ],
 *    [ //<-- these are the bodies array of slots
 *      [ // <-- this is a components array of choices for position at index
 *        { the component data }
 *      ]
 *    ],
 *  ]
 *
 * @param BusinessRules
 * @returns
 */
export function withLayoutAccordion<T extends InjectedProps>(
  components: (React.FC<T> & { dataModel: any; wraps: any })[][][],
  BusinessRules?: ComponentType<BusinessRulesType>
): React.FC<Omit<T, keyof InjectedProps>> {
  const Rules = withBypassComponent(
    BusinessRules as React.FC,
    !!BusinessRules
  ) as ComponentType<BusinessRulesType>;

  function LayoutAccordionComponent(props: Omit<T, keyof InjectedProps>) {
    const [mounted, setMounted] = useState(false);
    const [expanded, setExpanded] = useState<Expanded>({});
    const [accordionHydrated, setAccordionHydrated] = useState(false);
    const { instance } = useContext<IAppContext>(AppContext);
    const thdSeoBotDetected = instance?.thdSEOBotDetection;
    const handleChange = (index: number) => {
      return (evt?: any, stateVal?: boolean) => {
        setExpanded((state) => ({
          ...state,
          [index]: typeof stateVal !== 'undefined' ? stateVal : !state[index],
        }));
      };
    };

    const headers = components[0];
    const len = components[0].length;
    const defaultChoices = [
      new Array(len).fill({ index: 0, data: null }),
      new Array(len).fill({ index: 0, data: null }),
    ];
    const {
      fusionSlotName,
      fusionComponentName,
      fusionClassName,
      config,
      slot,
      sduiData,
      queryProvider,
      isDynamic = false,
      ...rest
    } = props as T;
    let choices = sduiData || defaultChoices;

    const triggerAccordionMountEvent = () => {
      if (!choices) return;

      const wrapperIds = headers.map((header, index) => {
        return `${fusionSlotName}-controlled-accordion-${index}-wrapper`;
      });

      window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('accordion.mount', {
        wrapperIds,
        setExpanded: handleChange,
      });
    };
    useEffect(() => {
      if (mounted) {
        triggerAccordionMountEvent();
      }
    }, [mounted]);

    useEffect(() => {
      if (accordionHydrated) {
        triggerAccordionMountEvent();
      }
    }, [accordionHydrated]);

    useEffect(() => {
      setMounted(true);
    }, []);

    // [1][0] === [1] is body element [0] index to later support multiple alternatives
    const bodies = components[1].map((Component, index) => {
      const {
        name,
        props: { hydrator, dynamic, errorBoundary },
      } = config.slots[slot].components[1][index].components[
        choices[1][index].index
      ];
      return Component;
    }); // do not use flat!
    const { fusionClassName: accordionFusionClassName } = config.slots[slot];

    const { dynamicRendering = {} } = config;

    const pageType = Object.keys(dynamicRendering).find(
      (i) => dynamicRendering[i] === true
    );

    const renderAccordion = () => (
      <div
        className={cs(
          'sui-min-h-sm sui-max-w-screen-2xl',
          accordionFusionClassName
        )}
      >
        {bodies.map((Component: any, index: number) => {
          const {
            name,
            props: { fusionClassName: bodyFusionClassName = '' },
          } = config.slots[slot].components[1][index].components[
            choices[1][index].index
          ];
          // const { props: { fusionClassName: headerFusionClassName } } = config.slots[slot].components[0][index];
          const Header = headers[index];
          const headerComponent = config.slots[slot].components[0][index].components[
            choices[0][index].index
          ];
          const headerProps = {
            ...headerComponent.props,
          };
          const bodyComponent = config.slots[slot].components[1][index].components[
            choices[1][index].index
          ];
          const BodyComponent = Array.isArray(Component)
            ? Component[choices[1][index].index]
            : Component;

          const bodyProps = {
            ...bodyComponent.props,
          };

          if (config.slots[slot].isSDUI && bodyProps.hydrator) {
            // hydrators and SDUI is incompatbile.
            // sdui is not server side rendered at all, so a component
            // that is in hydrator cant be also sdui.
            // hydrator works by writing section html with matching id on serverside.
            // if its not there it doesnt work.
            delete bodyProps.hydrator;
          }

          const HeaderComponent = Array.isArray(Header)
            ? Header[choices[0][index].index]
            : Header;
          return (
            <Rules
              key={index}
              itemId={rest.itemId}
              componentDisplayName={
                HeaderComponent.displayName || Component.name
              }
              props={rest as T}
            >
              {({ props: additionalProps }) => (
                <div id={`${fusionSlotName}-controlled-accordion-${index}-wrapper`}>
                  <Accordion
                    key={index}
                    expanded={thdSeoBotDetected || !!expanded[index]}
                    onChange={handleChange(index)}
                  >
                    <AccordionHeader>
                      <HeaderComponent
                        config={config}
                        slot={slot}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...headerProps}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...additionalProps}
                      />
                    </AccordionHeader>
                    <AccordionBody>
                      <BodyComponent
                        hydratorId={`${fusionSlotName}-controlled-body-${index}`}
                        config={config}
                        slot={slot}
                        queryProvider={queryProvider}
                        skipWrapper
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...bodyProps}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...additionalProps}
                      />
                    </AccordionBody>
                  </Accordion>
                </div>
              )}
            </Rules>
          );
        })}
      </div>
    );

    return (
      <div
        data-fusion-slot={fusionSlotName}
        data-fusion-component="accordion"
      >
        <ErrorBoundary name="collapsible-sections">
          {isDynamic && (
            <DynamicComponent pageType={pageType}>
              <Hydrator
                id="collapsible-sections-accordion"
                className="sui-flex-wrap sui-flex-auto sui-w-full"
                afterHydrate={() => setAccordionHydrated(true)}
                placeholder={(
                  <>
                    <div className="sui-flex sui-flex-col sui-w-full sui-gap-2">
                      <>
                        {bodies.map((Component: any, index: number) => {
                          const {
                            export: componentExport,
                          } = config.slots[slot].components[1][index].components[
                            choices[1][index].index
                          ];
                          return (
                            <div
                              id={`${fusionSlotName}-controlled-accordion-${index}-wrapper`}
                              className="sui-w-full"
                              key={`${componentExport}-${index}`}
                            // ref={productOverviewRef}
                            >
                              <Skeleton grow>
                                <SkeletonContent disablePadding>
                                  <SkeletonLine numberOfLines={2} fullWidth />
                                </SkeletonContent>
                              </Skeleton>
                            </div>
                          );
                        })}
                      </>
                    </div>
                  </>
                )}
              >
                {renderAccordion()}
              </Hydrator>
            </DynamicComponent>
          )}
          {!isDynamic && <>{renderAccordion()}</>}
        </ErrorBoundary>
      </div>
    );
  }
  // @todo update to include all - components[0][n]
  const dataModels = components[0]
    .flat()
    .map((titleComponent) => {
      return titleComponent.dataModel || {};
    })
    .concat(components[1].flat())
    .map((bodyComponent) => {
      return bodyComponent.dataModel || {};
    });
  LayoutAccordionComponent.dataModel = extend({}, ...dataModels);

  return LayoutAccordionComponent;
}
