import React from 'react';
import { Footer } from '../Footer/Footer';
import { calculateSize } from '../../../../data/paint-modals';
/* eslint-disable tailwindcss/no-arbitrary-value */
/* eslint-disable max-len */

export const EasyEstimator = () => {
  const { estimatesPerRoom } = calculateSize;
  return (
    <div className="sm:sui-mt-4 sui-mb-8">
      <div className="sui-flex sui-flex-col sm:sui-flex-row sui-justify-around">
        {estimatesPerRoom.map((room) => {
          const { name, image, roomSize, amountNeeded } = room;
          return (
            <div
              className="sui-flex sm:sui-flex-col sui-flex-row-reverse sm:sui-items-center sui-items-end sui-justify-end sui-mb-5 sui-flex-1"
              key={name}
            >
              <div className="sm:sui-mb-5">
                <img src={image} alt={name} height="auto" width="auto" />
              </div>
              <div
                className="sm:sui-items-center sui-flex sui-flex-col sui-font-bold sui-flex-[0_1_50%] sm:sui-flex-none"
              >
                <div className="sui-flex sm:sui-flex-row sui-flex-col">
                  Average&nbsp;
                  <span>{name}</span>
                </div>
                <div className="sui-flex sm:sui-flex-row sui-flex-col">
                  <span>{roomSize}&nbsp;</span>
                  square feet
                </div>
                <div className="sui-mt-2">{amountNeeded}</div>
              </div>
            </div>
          );
        })}
      </div>
      <Footer />
    </div>
  );
};

EasyEstimator.displayName = 'EasyEstimator';
