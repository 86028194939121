import React, { useContext } from 'react';
import classNames from 'classnames';
import {
  bool,
  node,
  number,
  oneOfType,
  string
} from 'prop-types';
import { TextField, InputAdornment } from '@one-thd/sui-atomic-components';
import { getError } from '../../helpers/errorHandlingUtil';
import { CalculatorContext } from './CalculatorProvider';
import {
  LOWER_CASE_DEPTH,
  LOWER_CASE_DESCRIPTION,
  LOWER_CASE_LENGTH,
  LOWER_CASE_SQUARE_FOOTAGE,
  LOWER_CASE_WIDTH
} from '../../constants';

// Regex is loose so that users are free to type out what they need without too many restrictions
// For more info on this Regex, try it out at https://ihateregex.io/playground
const looseNumberRegex = /^[0-9\b]{0,5}?([.]([0-9\b]{1,2})?)?$/;
const noDigitsRegex = /^[\D]*$/;

export const CalculatorInputElement = ({
  endAdornment,
  hideLabel,
  index,
  isError,
  labelName,
  propName,
  propValue
}) => {
  const { saveToLocalStorageFunc, setCalculateStateAndRef, resolveNames } = useContext(CalculatorContext);
  const lowerCasePropName = propName.toLowerCase();
  const regexPattern = lowerCasePropName === LOWER_CASE_DESCRIPTION
    ? noDigitsRegex
    : looseNumberRegex;

  const onChange = (event) => {
    const { value = '' } = event.target;
    if (value === '' || regexPattern.test(value)) {
      setCalculateStateAndRef((prevState) => {
        const { areas, areaErrors } = prevState;

        // get updated areas
        const newAreas = JSON.parse(JSON.stringify(areas));
        const newArea = newAreas[index];
        if (lowerCasePropName === LOWER_CASE_SQUARE_FOOTAGE) {
          newArea.squareFootage = value;
          newArea.length = '';
          newArea.width = '';
        } else if (lowerCasePropName === LOWER_CASE_DESCRIPTION) {
          newArea.name = value;
        } else {
          newArea[lowerCasePropName] = value;
          if ([LOWER_CASE_LENGTH, LOWER_CASE_WIDTH].includes(lowerCasePropName)
              && newArea.length && newArea.width) {
            newArea.squareFootage = (newArea.length * newArea.width).toString();
          }
        }
        // get updated area errors
        const errorCal = getError({
          name: lowerCasePropName, index, prevAreas: areas, newAreas
        });
        const newAreaErrors = JSON.parse(JSON.stringify(areaErrors));
        if (lowerCasePropName === LOWER_CASE_SQUARE_FOOTAGE) {
          newAreaErrors[index].squareFootage = errorCal;
        } else if (lowerCasePropName === LOWER_CASE_DESCRIPTION) {
          newAreas.forEach((element, dupeIndex) => {
            // if current field has errors, add errors to all duplicate names
            if (errorCal && (element.name.toLowerCase().trim() === newArea.name.toLowerCase().trim())) {
              newAreaErrors[dupeIndex].name = errorCal;
            } else if (!errorCal) {
              // if current field doesn't have errors, remove errors from other no-longer-duplicate names
              if (dupeIndex === index
                  || (element.name.toLowerCase().trim() === areas[index].name.toLowerCase().trim()
                      && newAreas.filter((innerArea) => innerArea.name === areas[index].name)).length <= 1) {
                newAreaErrors[dupeIndex].name = errorCal;
              }
            }
          });
        } else {
          newAreaErrors[index][lowerCasePropName] = errorCal;
        }
        return {
          ...prevState,
          areas: newAreas,
          areaErrors: newAreaErrors,
          changesAwaitingLocalStorage: true
        };
      });
    }
  };

  const inputClasses = classNames('sui-mr-3 sui-w-full', {
    'sui-mb-2': propName === 'length'
  });

  const endAdornmentProp = endAdornment
    ? <InputAdornment position="end">{endAdornment}</InputAdornment>
    : undefined;

  let errorMessage = '';
  if (isError) {
    if (lowerCasePropName === LOWER_CASE_DESCRIPTION) {
      errorMessage = 'Name is already in use, please use a different name';
    } else if (propValue === '') {
      errorMessage = 'Please enter all required fields';
    } else {
      errorMessage = 'Please enter a number above 0';
    }
  }

  return (
    <div className={inputClasses}>
      <TextField
        fullWidth
        label={!hideLabel ? `${labelName || propName}:` : null}
        onChange={onChange}
        type="string"
        name={lowerCasePropName}
        value={propValue}
        helpMessage={lowerCasePropName === LOWER_CASE_DEPTH ? '3 in. recommended' : null}
        status={errorMessage ? 'error' : null}
        statusMessage={errorMessage}
        placeholder={lowerCasePropName === LOWER_CASE_DESCRIPTION ? 'name' : null}
        InputProps={{
          onBlur: () => {
            resolveNames();
            saveToLocalStorageFunc();
          },
          endAdornment: endAdornmentProp
        }}
      />
    </div>
  );
};

CalculatorInputElement.propTypes = {
  endAdornment: oneOfType([
    string,
    node
  ]),
  hideLabel: bool,
  index: number.isRequired,
  isError: bool.isRequired,
  labelName: string,
  propName: string.isRequired,
  propValue: string.isRequired
};

CalculatorInputElement.defaultProps = {
  endAdornment: '',
  hideLabel: false,
  labelName: ''
};
