import React, {
  useRef, useState, useContext
} from 'react';
import { bool, func, number, string } from 'prop-types';
import { RatingMeter, Button, Skeleton, SkeletonBlock } from '@one-thd/sui-atomic-components';
import { ArrowDown } from '@one-thd/sui-icons';
import { useDataModel } from '@thd-nucleus/data-sources';
import { smoothScroll, getProductObject, getReviewInfo } from '../../productDetailsUtils';
import * as analytics from '../../analytics';
import { ProductDetailsContext } from '../ProductDetailsContext';
import { dataModel } from './ratings-and-reviews-data-model';
import { RatingsPopover } from './RatingsPopover/RatingsPopover.component';

const ProductDetailsRatings = (props) => {
  const {
    offsetselector, qaselector, pageType, target, hideRatingsIfHDPPSku, showBadge,
    disableReviewsIfZero, onClick, useHeaderIndex, hideRatings, hideWhenNoQA
  } = props;
  const {
    product, itemId, channel
  } = useContext(ProductDetailsContext);

  const [showPopover, setShowPopover] = useState(false);
  const ratingsRef = useRef();

  const { data: reviewData, loading: reviewLoading, error: reviewError } = useDataModel('reviews', {
    ssr: !(pageType === 'PEP'),
    variables: {
      itemId,
      filters: {
        isVerifiedPurchase: false,
        prosCons: null,
        starRatings: null
      },
      searchTerm: null,
      sortBy: 'photoreview',
      startIndex: 1
    }
  });

  const { data: qaData, loading: qaLoading, error: qaError } = useDataModel('questionsAnswers', {
    skip: pageType === 'PEP',
    variables: {
      itemId,
      pagesize: 8,
      searchTerm: '',
      sortBy: 'newest',
      startindex: 1
    }
  });

  const analyticsData = (element) => {
    analytics.track('click', {
      element
    });
  };

  if (reviewLoading || !product) {
    return (
      <Skeleton disablePadding>
        <SkeletonBlock height={8} width={32} />
      </Skeleton>
    );
  }

  const openPopover = () => {
    setShowPopover(true);
  };

  const closePopover = () => {
    setShowPopover(false);
  };

  const handleRatingsClick = (event) => {
    if (useHeaderIndex) {
      onClick(event, target, 3);
    } else {
      smoothScroll(event, target, offsetselector);
      onClick(target);
    }
    analyticsData('star rating');
  };

  const handleQuestionAnswerClick = (event) => {
    if (useHeaderIndex) {
      onClick(event, qaselector, 2);
    } else {
      smoothScroll(event, qaselector, offsetselector);
      onClick(qaselector);
    }
    analyticsData('questions and answers');
  };

  const itemObj = getProductObject(reviewData?.reviews, reviewData?.reviews?.TotalResults);
  const { averageRating, ratingsTitle } = getReviewInfo(itemObj, product, reviewData?.reviews?.TotalResults);
  const { skuClassification } = product?.identifiers || {};

  const isHiddenHDPPSku = (hideRatingsIfHDPPSku && skuClassification === 'hdpp');
  const isHiddenZeroReviews = (disableReviewsIfZero && reviewData?.reviews?.TotalResults === 0);

  return (
    <div className="sui-flex sui-flex-row sui-items-center" data-component="ProductDetailsRatings">
      { !isHiddenHDPPSku
        && !isHiddenZeroReviews
        && (
          <>
            {!hideRatings && (
              <>
                <div ref={ratingsRef} className="sui-flex sui-flex-row sui-items-center">
                  <span
                    id="product-details__review__target"
                    className="sui--ml-2"
                  >
                    <RatingMeter
                      value={averageRating}
                      label={averageRating !== 0 ? `(${reviewData?.reviews?.TotalResults})` : ''}
                      RatingProps={{
                        color: 'brand'
                      }}
                      data-testid="rating-button"
                      onClick={handleRatingsClick}
                      onMouseEnter={openPopover}
                      onFocus={openPopover}
                      onBlur={closePopover}
                      title={ratingsTitle}
                    />
                  </span>
                </div>
                {showBadge && (
                  <ArrowDown size="small" />
                )}
              </>
            )}
            {showBadge && channel === 'desktop'
              && ((!reviewError) && reviewData?.reviews)
              && ratingsRef.current
              && (
                <RatingsPopover
                  open={showPopover}
                  anchorEl={ratingsRef.current}
                  onClick={handleRatingsClick}
                  onClose={closePopover}
                  reviewStats={reviewData?.reviews}
                />
              )}
            {showBadge && channel === 'desktop'
              && (!(qaLoading || qaError) && qaData)
              && (
                (hideWhenNoQA && qaData?.questionsAnswers?.TotalResults > 0)
                || !hideWhenNoQA
              ) && (
              <Button
                onClick={handleQuestionAnswerClick}
                className={`${!hideRatings ? 'sui-ml-2' : 'sui-ml-0'} sui-cursor-pointer hover:sui-text-underline`}
                variant="ghost"
              >
                {qaData?.questionsAnswers?.TotalResults
                  ? `Questions & Answers\xA0(${qaData?.questionsAnswers?.TotalResults})`
                  : 'Questions & Answers\xA0'}
              </Button>
            )}
          </>
        )}
    </div>
  );
};

ProductDetailsRatings.propTypes = {
  offsetselector: string,
  pageType: string,
  qaselector: string,
  showBadge: bool,
  target: string,
  disableReviewsIfZero: bool,
  onClick: func,
  useHeaderIndex: bool,
  hideRatingsIfHDPPSku: bool,
  hideRatings: bool,
  hideWhenNoQA: bool
};

ProductDetailsRatings.defaultProps = {
  offsetselector: null,
  pageType: 'PIP',
  qaselector: null,
  showBadge: false,
  target: null,
  disableReviewsIfZero: false,
  onClick: () => { },
  useHeaderIndex: false,
  hideRatingsIfHDPPSku: false,
  hideRatings: false,
  hideWhenNoQA: false
};

ProductDetailsRatings.dataModel = dataModel;

export default ProductDetailsRatings;
