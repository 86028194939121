const QUESTION_DISPLAY_PER_PAGE = 8;
const DEFAULT_NUMBER_OF_PAGES = 1;
const DESKTOP_PAGE_2_START_INDEX = 5;
const DESKTOP_PAGE_2_START_INDEX_PPQ = QUESTION_DISPLAY_PER_PAGE + 1;
const BASE_10 = 10;
const TEN_MB = 10000000;
const TOTAL_RESULTS_LIMIT = 500;

export {
  QUESTION_DISPLAY_PER_PAGE,
  DEFAULT_NUMBER_OF_PAGES,
  DESKTOP_PAGE_2_START_INDEX,
  DESKTOP_PAGE_2_START_INDEX_PPQ,
  BASE_10,
  TEN_MB,
  TOTAL_RESULTS_LIMIT
};
