import React from 'react';
import { string, number, bool } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { AccordionTitleComponent } from './Accordion/AccordionTitleComponent';
import { AccordionTitlePlaceholder } from './Accordion/AccordionTitlePlaceholder';
import { QAContextProvider } from '../context/QAContext';
import { dataModel } from './dataModel';
import './QuestionsAndAnswers.scss';

export const QuestionsAndAnswersAccordionTitleComponent = ({ itemId, canonicalUrl, seoPageNumber, noSpacing }) => {
  return (
    <div data-component="QuestionsAndAnswersAccordionTitle">
      {/* eslint-disable-next-line */}
      <div className="sui-flex-[0_0_100%] sui-w-max">
        <QAContextProvider itemId={itemId} seoPageNumber={seoPageNumber}>
          <QueryProvider cacheKey="question-answer-accordion-title">
            <AccordionTitleComponent itemId={itemId} noSpacing={noSpacing} />
          </QueryProvider>
        </QAContextProvider>
      </div>
    </div>
  );
};

QuestionsAndAnswersAccordionTitleComponent.displayName = 'QuestionsAndAnswersAccordionTitle';

QuestionsAndAnswersAccordionTitleComponent.propTypes = {
  itemId: string.isRequired,
  canonicalUrl: string,
  seoPageNumber: number,
  noSpacing: bool,
};

QuestionsAndAnswersAccordionTitleComponent.defaultProps = {
  canonicalUrl: null,
  seoPageNumber: null,
  noSpacing: false
};

QuestionsAndAnswersAccordionTitleComponent.dataModel = dataModel;

const HydratedComponent = withHydrator({
  delay: 2000,
  id: 'questions_answers_accordion_title',
  placeholder: <AccordionTitlePlaceholder />,
  scrollBuffer: 750
}, QuestionsAndAnswersAccordionTitleComponent);

const DynamicComponent = withDynamicComponent(HydratedComponent);

export const QuestionsAndAnswersAccordionTitle = withErrorBoundary(DynamicComponent);

QuestionsAndAnswersAccordionTitle.propTypes = {
  itemId: string.isRequired,
  canonicalUrl: string,
  seoPageNumber: number
};

QuestionsAndAnswersAccordionTitle.defaultProps = {
  canonicalUrl: null,
  seoPageNumber: null
};

QuestionsAndAnswersAccordionTitle.dataModel = dataModel;
