import { useEffect } from 'react';
import { useNavigate } from '@thd-olt-component-react/router';

export const useEventListener = (props = {}) => {

  const {
    itemId,
    setItemId
  } = props;

  const navigate = useNavigate();

  useEffect(() => {
    // supersku
    window.LIFE_CYCLE_EVENT_BUS.on('super-sku.change', ({ output }) => {
      const { item, slug, canonicalUrl } = output;
      if (canonicalUrl) {
        navigate(canonicalUrl, { state: { disableScroll: true } });
      }
    });

    window.LIFE_CYCLE_EVENT_BUS.on('configurator.configurator_omsId_event', ({ output }) => {
      if (output !== itemId) {
        setItemId(output);
      }
    });

    // @todo sticky nav

  }, []);
};
