export const getDefaultErrorSchema = () => {
  return {
    name: false,
    length: false,
    width: false,
    depth: false,
    squareFootage: false
  };
};

const getNumberValueIsError = (prevValue, newValue, useStrictValidation) => {
  /*
    useStrictValidation
    - When it's true, we return error ANYTIME the value is empty or 0
    - When it's false, we return error on all 0's, but for empty values,
      we check first to see if prevValue is empty before we throw an error
      - This is to avoid false errors when creating a new "Area"
  */
  if (useStrictValidation) {
    return newValue === '' || Number(newValue) === 0;
  }
  return (prevValue !== '' && newValue === '') || Number(newValue) === 0;
};

export const getError = ({
  name, index, prevAreas, newAreas
}) => {
  const newArea = newAreas[index];
  const prevArea = prevAreas[index];
  if (name === 'description') {
    return false;
  }
  if (name === 'square footage') {
    // eslint-disable-next-line max-len
    return getNumberValueIsError(prevArea.squareFootage, newArea.squareFootage, false);
  }
  return getNumberValueIsError(prevArea[name], newArea[name], false);
};

export const hasError = (areas, errors) => errors.some((errorSchema, errorSchemaIndex) => {
  const excludedKeys = areas[errorSchemaIndex].calcByArea ? ['width', 'length'] : ['squareFootage'];
  return Object.keys(errorSchema)
    .filter((key) => !excludedKeys.includes(key))
    .some((key) => errorSchema[key]);
});

export const checkForAnyErrors = ({
  calculatorType, areas
}) => {
  let errors = new Array(areas.length).fill('').map(() => getDefaultErrorSchema());
  for (let index = 0; index < areas.length; index += 1) {
    const {
      length: newLength,
      width: newWidth,
      depth: newDepth,
      squareFootage: newSqFt,
      name: newName,
      calcByArea: newCalcByArea
    } = areas[index];
    // Number validations (non-empty and non-zeros)
    if (newCalcByArea) {
      errors[index].squareFootage = getNumberValueIsError(newSqFt, newSqFt, true);
    } else {
      errors[index].length = getNumberValueIsError(newLength, newLength, true);
      errors[index].width = getNumberValueIsError(newWidth, newWidth, true);
    }
    if (calculatorType === 'Volume') {
      errors[index].depth = getNumberValueIsError(newDepth, newDepth, true);
    }
    // Duplicate name validation
    errors[index].name = areas
      .filter((area) => area.name.toLowerCase().trim() === newName.toLowerCase().trim())
      .length > 1;
  }
  return errors;
};
